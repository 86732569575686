import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useParentsImport() {
  // Use toast
  const toast = useToast()
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  const getHeadersFromFile = formData => axios
    .post('/auth/import/headers', formData)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting headers',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const fetchFieldsList = queryParams => axios
    .get('/auth/import/parents/fields', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching PPA list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const importParents = formData => axios
    .post('/auth/import/parents/import', formData)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success importing Parents',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error importing Applications',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    programId,
    getHeadersFromFile,
    fetchFieldsList,
    importParents,
  }
}
