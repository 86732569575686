<template>
  <b-card>

    <b-row>
      <b-col
        cols="6"
      >
        <b-form-group
          label="Select File"
          class="mb-0"
        >
          <b-form-file
            ref="refInputEl"
            v-model="importFile"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="Choose file"
            @change="handleFileChange"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="3"
        align-self="end"
      >
        <b-button
          variant="primary"
          :disabled="!importFile || isFileLoading"
          @click="selectFile"
        >
          <b-spinner
            v-if="isFileLoading"
            label="Spinning"
            small
            class="mr-1"
          />
          <span>Get file headers</span>
        </b-button>
      </b-col>
    </b-row>

    <template v-if="mappedFields.length">
      <b-row
        class="mt-3"
      >
        <b-col cols="12">
          <h5>Import Mapping Wizard</h5>
          <hr>
        </b-col>
        <b-col cols="12">
          <b-alert
            show
            class="p-1"
          >
            <p>For importing applications you should map required fields with columns from the table.</p>
            <p>Here is the list of them: <b>{{ requiredFieldsNames }}</b></p>
          </b-alert>
        </b-col>
        <b-col
          cols="6"
          class="mb-1"
        >
          <h6>Columns</h6>
        </b-col>
        <b-col
          cols="6"
          class="mb-1"
        >
          <h6>PPA</h6>
        </b-col>
      </b-row>

      <b-row
        v-for="(field, index) in mappedFields"
        :key="index"
        class="mb-1"
      >
        <b-col
          cols="6"
          align-self="center"
        >
          {{ field.name }}
        </b-col>
        <b-col
          cols="6"
        >
          <v-select
            v-model="field.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fieldsList"
            label="name"
            :reduce="option => option.value"
            :placeholder="'Select field'"
            taggable
            multiple
          >
            <template
              #selected-option="{ name }"
            >
              <div
                class="d-flex justify-content-between"
              >
                <span class="menu-item-label">{{ name }}</span>
              </div>
            </template>

            <template
              #option="{ name, type, required }"
            >
              <div
                class="d-flex justify-content-between"
              >
                <span
                  class="menu-item-label"
                  :class="{'text-danger': required }"
                >{{ name }}</span>
                <b-badge
                  class="mr-2"
                  pill
                  :variant="getColor(type)"
                >
                  {{ type }}
                </b-badge>
              </div>
            </template>
          </v-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="showValidationError && notSelectedRequiredFieldsNames.length"
            class="p-1"
          >
            <p>You should map all required fields.</p>
            <p>Here is the list of not selected fields: <b>{{ notSelectedRequiredFieldsNames }}</b></p>
          </b-alert>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          variant="outline-primary"
          class="mr-2"
          @click="$router.back()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="handleForm"
        >
          Start Import
        </b-button>
      </div>
    </template>

    <div
      v-if="validationErrors.length"
      class="form-group pt-1"
    >
      <p
        v-for="(validationError, index) in validationErrors"
        :key="index"
        class="text-center text-danger"
      >
        {{ validationError }}
      </p>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormFile, BSpinner, BButton, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useParentsImport from '@/views/admin/parents/parents-import/useParentsImport'

export default {
  name: 'ParentsImport',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormFile,
    BSpinner,
    BButton,
    BBadge,
    BAlert,

    vSelect,
  },
  data() {
    return {
      importFile: null,
      validationErrors: [],
      mappedFields: [],
      fieldsList: [],
      requiredFields: [],
      showValidationError: false,
      isFileLoading: false,
      isImportingParents: false,
    }
  },
  setup() {
    const {
      programId,
      getHeadersFromFile,
      fetchFieldsList,
      importParents,
    } = useParentsImport()

    return {
      programId,
      getHeadersFromFile,
      fetchFieldsList,
      importParents,
    }
  },
  computed: {
    requiredFieldsNames() {
      return this.requiredFields
        .map(field => field.name)
        .join(', ')
    },
    notSelectedRequiredFieldsNames() {
      const selectedValues = this.mappedFields.map(field => field.value).flat()
      return this.requiredFields
        .filter(field => !selectedValues.includes(field.value))
        .map(field => field.name)
        .join(', ')
    },
  },
  methods: {
    handleFileChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.importFile = e.target.files[0]
      this.mappedFields = []
    },
    async setFieldsListAndRequiredFields() {
      const queryParams = {
        program_id: this.programId,
      }
      this.fieldsList = await this.fetchFieldsList(queryParams)
      this.requiredFields = this.fieldsList.filter(item => item.required)
    },
    async selectFile() {
      const formData = new FormData()
      formData.append('file', this.importFile)

      this.validationErrors = []

      this.isFileLoading = true

      try {
        const response = await this.getHeadersFromFile(formData)
        await this.setFieldsListAndRequiredFields()
        this.setMappedFields(response)
      } catch (error) {
        if (error.status === 422) {
          const validationErrors = Object.values(error.response.data.errors)
          this.validationErrors = validationErrors.flat()
        }
      } finally {
        this.isFileLoading = false
      }
    },
    setMappedFields(data) {
      this.mappedFields = data.reduce((arr, item) => {
        arr.push({
          name: item,
          value: null,
        })
        return arr
      }, [])
    },
    getColor(type) {
      switch (type) {
        case 'user':
          return 'light-info'
        case 'group':
          return 'light-warning'
        case 'group type':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    handleForm() {
      if (!this.notSelectedRequiredFieldsNames) {
        this.handleImportParents()
      } else {
        this.showValidationError = true
      }
    },
    async handleImportParents() {
      const formData = new FormData()
      formData.append('file', this.importFile)
      formData.append('program_id', this.programId)
      formData.append('mapped_data', JSON.stringify(this.mappedFields))
      formData.append('role', this.role)

      this.validationErrors = []
      this.isImportingParents = true

      try {
        const response = await this.importParents(formData)
        if (response.status === 422 || response.status === 500) {
          const validationErrors = Object.values(response.data.errors)
          this.validationErrors = validationErrors.flat()
        } else {
          this.$router.back()
        }
      } finally {
        this.isImportingParents = false
      }
    },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select';
</style>
